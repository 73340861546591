<template>
    <section id="ProjReObject">
        

        <div class="container">
        
        <!-- PAGE TITLE -->
            <page-title 
                title="Pressule - Re-Object" 
                subtitle="Il brief richiedeva di creare un marchio che utilizza <u>oggetti riciclati per creare piccoli oggetti di design</u>. Ho deciso di utilizzare capsule di caffè in alluminio per creare due oggetti di uso quotidiano. Un portachiavi e un sottopentola vengono creati tramite la pressa per cialde del caffè. Il caffè viene invece utilizzato come fertilizzante per gli allevamenti." 
            />
        <!-- --------------------- -->

        <!-- BOX WITH TEXT AND IMG -->
            <!-- Img is a path and start automatically from imgs folder.. if want find image in a folder inside imgs folder u need to specify which folder before img name (folder/name.jpg) -->
            <!-- imgType: use cover or contain -->
            <text-and-img title='Branding' imgType="cover" img='proj-reobject/logo-img-small.png'>
                <p>Il nome deriva dall'unione delle parole "press" e "capsule". Il logo vuole fare riferimento visivamente all'immagine stilizzata della capsula di caffè pressata; può essere presentato in due versioni: La versione classica (Logo) e la versione con l'immagine di una capsula disposta sotto, che rafforza visivamente il logo</p>
            </text-and-img>
        <!-- --------------------- -->

        <!-- PHOTO SHOWREEL -->
            <Showreel
                title=""
                :imgs="[
                    //Find is a path and start automatically from imgs folder..
                    //if want find image in a folder inside imgs folder u need to specify which folder before img name
                    //(folder/name.jpg)
                    {
                        find: 'proj-reobject/logo.png',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-reobject/logo-img.png',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-reobject/portachiavi.png',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-reobject/sottopentola.png',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                ]"
            />
        <!-- --------------------- -->

        </div>
    </section>
</template>

<script>
import PageTitle from '../../components/small/PageTitle.vue'
import Showreel from '../../components/works-project/Showreel.vue'
import TextAndImg from '../../components/works-project/TextAndImg.vue'

export default {
    name: 'ProjReobject',
    components: {
        PageTitle,
        Showreel,
        TextAndImg,
    },
    metaInfo: {
        title: 'Reobject',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: 'Ho deciso di utilizzare capsule di caffè in alluminio per creare due oggetti di uso quotidiano. Un portachiavi e un sottopentola vengono creati tramite la pressa per cialde del caffè riciclando l\'alluminio; il caffè viene invece utilizzato come fertilizzante per gli allevamenti.',
        }],
    },
    data(){
        return{
            pages: [ //use the link to imgs -- put the files in src/book/folder and will be see when website is uploaded
                "https://arisgoi.it/books/test/test-1.jpg",
                "https://arisgoi.it/books/test/test-2.jpg",
                "https://arisgoi.it/books/test/test-3.jpg",
                "https://arisgoi.it/books/test/test-4.jpg",
                "https://arisgoi.it/books/test/test-5.jpg",
                "https://arisgoi.it/books/test/testImg.jpg",
            ],
        }
    },
    methods: {
        //
    }
}
</script>


<style scoped lang="scss">
@import '@/scss/_variables.scss';

</style>