var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"ProjReObject"}},[_c('div',{staticClass:"container"},[_c('page-title',{attrs:{"title":"Pressule - Re-Object","subtitle":"Il brief richiedeva di creare un marchio che utilizza <u>oggetti riciclati per creare piccoli oggetti di design</u>. Ho deciso di utilizzare capsule di caffè in alluminio per creare due oggetti di uso quotidiano. Un portachiavi e un sottopentola vengono creati tramite la pressa per cialde del caffè. Il caffè viene invece utilizzato come fertilizzante per gli allevamenti."}}),_c('text-and-img',{attrs:{"title":"Branding","imgType":"cover","img":"proj-reobject/logo-img-small.png"}},[_c('p',[_vm._v("Il nome deriva dall'unione delle parole \"press\" e \"capsule\". Il logo vuole fare riferimento visivamente all'immagine stilizzata della capsula di caffè pressata; può essere presentato in due versioni: La versione classica (Logo) e la versione con l'immagine di una capsula disposta sotto, che rafforza visivamente il logo")])]),_c('Showreel',{attrs:{"title":"","imgs":[
                //Find is a path and start automatically from imgs folder..
                //if want find image in a folder inside imgs folder u need to specify which folder before img name
                //(folder/name.jpg)
                {
                    find: 'proj-reobject/logo.png',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-reobject/logo-img.png',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-reobject/portachiavi.png',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-reobject/sottopentola.png',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                } ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }